import { Box, Grid, withStyles } from '@material-ui/core';
import classNames from 'classnames';
import { TextField } from 'mui-rff';
import React, { FC } from 'react';
import { Form as FinalForm } from 'react-final-form';
import { useDispatch, useSelector } from 'react-redux';
import {
  Button,
  IconButton,
  IconCloseV2,
  InlineTextButton,
  TypographyWrapper,
} from '../../components';
import { ButtonVariant } from '../../components/Button/Button';
import { useIsMobile } from '../../hooks/useIsMobile';
import { defaultTreetStyles } from '../../shopConfig/config';
import { EmailSubscribeSource } from '../../types/apollo/generated/types.generated';
import { RequestStatus } from '../../types/requestStatus';
import { required } from '../../util/validators';
import ReferBrandModal from './ReferBrandModal';
import { subscribeEmailToTreetShop, TreetShopLandingPageState } from './TreetShopLandingPage.duck';
import css from './TreetShopLandingPage.module.css';
import { ModalType } from '../../ducks/modal.duck';
import { useActiveModal } from '../../hooks/useActiveModal';
import { TypographyFormat } from '../../components/TypographyWrapper/TypographyWrapper';

const SUBSCRIBE_HEIGHT = '48px';
export const inputStyles = {
  root: {
    // Ensures placeholder is centered
    '& .MuiInputLabel-outlined': {
      transform: 'translate(16px, 16px) scale(1)',
    },
    '& .MuiInputLabel-outlined.MuiInputLabel-shrink': {
      transform: 'translate(14px, -6px) scale(0.75)',
    },
    '& .MuiOutlinedInput-root': {
      height: SUBSCRIBE_HEIGHT,
    },
  },
};

const EmailTextField = withStyles(inputStyles)(TextField);

const SubscribeForm: FC = () => {
  const dispatch = useDispatch();

  const { subscribeTreetShopStatus } = useSelector<any>(
    (state) => state.TreetShopLandingPage
  ) as TreetShopLandingPageState;

  const inProgress = subscribeTreetShopStatus === RequestStatus.Pending;
  const isSubmitted = subscribeTreetShopStatus === RequestStatus.Success;

  const onSubmit = (values: any) => {
    const { email } = values;
    return dispatch(subscribeEmailToTreetShop(email, EmailSubscribeSource.TreetShopFooter));
  };

  return (
    <Box width="100%" maxWidth="800px" alignItems="flex-end">
      {isSubmitted && (
        <TypographyWrapper typographyOverrides={{ align: 'center' }} variant="body1">
          Subscribed!
        </TypographyWrapper>
      )}
      {!isSubmitted && (
        <FinalForm
          onSubmit={onSubmit}
          render={(fieldRenderProps) => {
            const { handleSubmit } = fieldRenderProps;

            return (
              <form onSubmit={handleSubmit}>
                <Grid alignItems="flex-end" container spacing={2}>
                  <Grid item xs={12} md={6}>
                    <EmailTextField
                      id="email"
                      name="email"
                      label="Email"
                      variant="outlined"
                      fieldProps={{
                        validate: required('Please enter your email'),
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Button
                      variant={ButtonVariant.Primary}
                      type="submit"
                      inProgress={inProgress}
                      style={{
                        height: SUBSCRIBE_HEIGHT,
                      }}
                    >
                      Notify Me When New Brands Launch
                    </Button>
                  </Grid>
                </Grid>
              </form>
            );
          }}
        />
      )}
    </Box>
  );
};

const ReferBrand: FC = () => {
  const { isModalOpen, openModal, closeModal } = useActiveModal(ModalType.ReferBrand);

  const isMobile = useIsMobile();

  return (
    <>
      <Box display="flex" flexDirection={isMobile ? 'column' : 'row'} mt={3} alignItems="center">
        <TypographyWrapper variant="body2" typographyOverrides={{ display: 'inline' }}>
          Is there a brand you would like to see on Treet?&nbsp;
        </TypographyWrapper>
        <InlineTextButton onClick={openModal}>
          <TypographyWrapper
            variant="body2"
            format={TypographyFormat.Underlined}
            typographyOverrides={{ display: 'inline', style: { color: defaultTreetStyles.teal80 } }}
          >
            Let us know!
          </TypographyWrapper>
        </InlineTextButton>
      </Box>
      <ReferBrandModal open={isModalOpen} onClose={closeModal} />
    </>
  );
};

interface TreetShopFooterProps {
  isSticky: boolean;
  onClose: () => void;
}

const TreetShopFooter: FC<TreetShopFooterProps> = (props: TreetShopFooterProps) => {
  const { isSticky, onClose } = props;

  return (
    <Box className={classNames(css.footer, { [css.sticky]: isSticky })}>
      {isSticky && (
        <IconButton className={css.closeButton} icon={<IconCloseV2 />} onClick={onClose} />
      )}
      <Box display="flex" flexDirection="column" alignItems="center" p={4}>
        <SubscribeForm />
        <ReferBrand />
      </Box>
    </Box>
  );
};
export default TreetShopFooter;
