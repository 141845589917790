import React, { FC } from 'react';
import { Box } from '@material-ui/core';
import { TreetShopCategories } from './TreetShopCategories';
import { TreetShopCategory } from '../TreetShopLandingPage.utils';
import TypographyWrapper, {
  TypographyWeight,
} from '../../../components/TypographyWrapper/TypographyWrapper';
import css from './TreetShopTopbar.module.css';

interface TreetShopTopbarMobileMenuProps {
  categories: TreetShopCategory[];
}
const TreetShopTopbarMobileMenu: FC<TreetShopTopbarMobileMenuProps> = (props) => {
  const { categories } = props;

  return (
    <Box>
      <Box display="flex" pl={4} pt={3}>
        <TypographyWrapper variant="body1" weight={TypographyWeight.Bold}>
          categories
        </TypographyWrapper>
      </Box>
      <TreetShopCategories
        categories={categories}
        wrapperBoxOverrides={{ ml: 3, mt: 1.5, pl: 1 }}
        selectedCategoryClassName={css.selectedCategoryMobileMenu}
      />
    </Box>
  );
};

export default TreetShopTopbarMobileMenu;
